var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-password-field', _vm._b({
    attrs: {
      "dense": _vm.dense,
      "outlined": "",
      "hide-details": "auto"
    },
    model: {
      value: _vm.passwordConfirm,
      callback: function ($$v) {
        _vm.passwordConfirm = $$v;
      },
      expression: "passwordConfirm"
    }
  }, 'v-password-field', Object.assign({}, _vm.$attrs, {
    rules: _vm.rules
  }), false));

}
var staticRenderFns = []

export { render, staticRenderFns }
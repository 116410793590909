var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-btn', _vm._b({
    on: {
      "click": _vm.click
    }
  }, 'v-btn', _vm.$attrs, false), [_vm._t("default"), _c('v-file-input', _vm._b({
    ref: "fileInput",
    staticClass: "d-none",
    on: {
      "change": _vm.input
    },
    model: {
      value: _vm.file,
      callback: function ($$v) {
        _vm.file = $$v;
      },
      expression: "file"
    }
  }, 'v-file-input', {
    accept: _vm.accept
  }, false))], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }